.sat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
}

.sat-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: flex-end;
    justify-content: flex-end;
    margin-top: 15px;
}