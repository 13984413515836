:root {
  --color-font: #333333;
  --color-primary: #6a207f;
  --color-accent: #cc9933;
  --color-background: #4d4d4d;
  --color-font-darkgrey: #8a8a8a;
  --color-font-grey: #c1c1c1;
  --color-font-lightgrey: #e5e5e5;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

body p,
body h6,
body h5,
body h4,
body h3,
body h2,
body h1 {
  margin: 0;
}

main {
  min-height: 100vh;
}

.home-screen {
  margin: auto 20px;
}

.login-page {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 175px;
}

.login-page img {
  width: 250px;
}

.login-page>button {
  margin: 25px auto 10px;
}

.block-hidden {
  display: none !important;
}