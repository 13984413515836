.steps {
    margin-top: 20px;
}

.at-content {
    margin: 20px 10px 10px 10px;
    padding: 20px;
    min-height: 500px;
}

.at-container {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
    min-width: 800px;
}

.at-content .steps {
    display: flex;
    column-gap: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.center {
    width: 1300px;
}

.label {
    display: flex;
    justify-content: flex-end;
}

.ant-transfer-list {
    background-color: white;
}