.page-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 20px 10px 10px 10px;
    padding: 20px;
}

.app-content {
    height:100%
}
