div.account-form-buttons {
  margin-top: 20px;
}

.account-form-buttons button {
  margin: 0px 2px;
}

.account-form-fieldname-info {
  margin-left: 4px;
}

.account-view textarea {
  width: 450px;
}

.account-form-paragraph {
  max-width: 500px;
}

form {
  text-align: center
}

table {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  border: 0px;
  padding: 12px
}

td {
  border-bottom: 0px;
  border-collapse: collapse;
  text-align: left;
  padding: 12px
}

td.account-form-fieldname {
  text-align: left;
  vertical-align: top;
  font-weight: bold;
}

td.account-form-fieldvalue {
  min-width: 450px;
}

th {
  padding: 12px
}

div.account-form-buttons {
  margin-top: 20px;
}

.account-form-buttons button {
  margin: 0px 2px;
}

.account-form-fieldname-info {
  margin-left: 4px;
}

.account-view textarea {
  width: 450px;
}

.account-form-paragraph {
  max-width: 500px;
}

.account-form-button {
  margin: 10px;
}