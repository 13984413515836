.sa-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    padding: 30px;
}

.sa-buttons {
    align-self: flex-end;
    margin-top: 50px;
}