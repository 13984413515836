.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  background: white;
}

.header-logo img {
  width: 175px;
  margin: 5px 12px;
}

.header-user {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-user button {
  margin: 3px 0 3px 5px;
}

.header-background {
  background: white;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

@media (max-width: 500px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.ending > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}