.acc-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 20px 10px 10px 10px;
  padding: 20px;
  background-color: white;
}

.acc-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: 20px;
}

.card {
  min-width: 200px;
}

.full-width {
  width: 100%;
}

button.button-new-config {
  margin-top: 10px;
  width: 100px;
}